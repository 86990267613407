import { Form, Button, } from "antd"
import Modal from "antd/lib/modal/Modal"
import { useEffect, useRef, useState } from "react";
import { FormHiddenSubmitButton, FormInput, FormInputNumber, FormSelect, FormSwitch } from "../../../../components/FormItems/FormItems"
import './DealerModal.scss'
import proptypes from 'prop-types'
import { useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../../../utils/Notification";


const DealerModal = ({ modalVisible, setModalVisible, currentDealer, onAdd, onUpdate, onAmountUpdate, setCurrentDealer }) => {


  const [form] = Form.useForm();
  const submitRef = useRef()

  const [selectedDistributor, setSelectedDistributor] = useState(null)
  const [selectedSubDistributor, setSelectedSubDistributor] = useState(null)

  const loggedUser = useSelector(state => state.user.currentUser)
  const distributors = useSelector(state => state.user.allDistributors)
  const subDistributors = useSelector(state => state.user.allSubDistributors)

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const onClose = () => {
    setModalVisible(false)
    form.resetFields()
    setCurrentDealer(null)
  }

  useEffect(() => {
    if (currentDealer && distributors.length) {
      onDistributorChange(currentDealer.distributor?._id)
      onSubDistributorChange(currentDealer.subDistributor?._id)
    }
    else if (loggedUser?.role === 'distributor') {
      setSelectedDistributor(loggedUser)
    } else if (loggedUser?.role === 'subDistributor') {
      onSubDistributorChange(loggedUser._id)
    }
  }, [currentDealer]);//eslint-disable-line

  const distributorsOptions = () => {
    if (distributors.length) {
      return distributors.map(o => ({
        label: o.name,
        value: o._id,
        amount: o.wallet.amount
      }))
    } else if (loggedUser?.role === 'distributor') {
      return [{
        label: loggedUser.name,
        value: loggedUser._id,
        amount: loggedUser.wallet.amount
      }]
    }
  }

  const subDistributorsOptions = () => {
    if (selectedDistributor) {
      return subDistributors.filter(sd => sd.distributor?._id === selectedDistributor?._id).map(o => ({
        label: o.name,
        value: o._id,
        amount: o.wallet.amount
      }))
    } else if (loggedUser?.role === 'subDistributor') {
      return [{
        label: loggedUser.name,
        value: loggedUser._id,
        amount: loggedUser.wallet.amount
      }]

    }
    return []
  }

  const onDistributorChange = (value) => {
    const distributor = distributors.find(d => d._id === value)
    if (distributor) {
      setSelectedDistributor(distributor)
    }
  }

  const onSubDistributorChange = (value) => {
    const subdistributor = subDistributors.find(d => d._id === value)
    if (subdistributor) {
      setSelectedSubDistributor(subdistributor)
    }
    else if (loggedUser?.role === 'subDistributor') {
      setSelectedSubDistributor(loggedUser)

    }
  }

  return (
    <Modal
      className='todo_modal'
      style={{ borderRadius: 8 }}
      bodyStyle={{ borderRadius: 8 }}
      destroyOnClose
      title={currentDealer ? 'Update Dealer' : 'Add Dealer'}
      onOk={() => submitRef.current.click()}
      visible={modalVisible}
      onCancel={onClose}>
      <Form className='form' layout='vertical' form={form} preserve={false} onFinishFailed={onFinishFailed} onFinish={(values) => {
        console.log('ok')
        if (currentDealer) {
          onUpdate(values)
          onClose()
        } else {
          onAdd(values)
          onClose()
        }
        setModalVisible(false)
      }}>
        <FormInput label='Name' name='name' required type='text' placeholder='Enter Name' initialValue={currentDealer ? currentDealer.name : ''} styles={null} />
        <FormInput disabled={currentDealer} label='Username' name='username' required type='text' placeholder='Enter Username' initialValue={currentDealer ? currentDealer.username : ''} styles={null} />
        {currentDealer ? null : <FormInput label='Password' name='password' required type='password' placeholder='Enter Password' styles={null} />}
        <FormInputNumber maxlength={10} styles={{ width: '100%' }} label='Phone' name='phone' required type='text' placeholder='Enter Phone' initialValue={currentDealer ? currentDealer.phone : null} />
        {loggedUser?.role === 'admin' ?
          <div>
            <FormSelect onChange={(value) => onDistributorChange(value)} label='Distributor' name='distributor' placeholder={'Select Distributor'} options={distributorsOptions()} required initialValue={currentDealer ? currentDealer.distributor?._id : null} />
            <FormSelect onChange={(value) => onSubDistributorChange(value)} label='SubDistributor' name='subDistributor' placeholder={'Select Sub Distributor'} options={subDistributorsOptions()} required initialValue={currentDealer ? currentDealer.subDistributor._id : null} />
          </div>
          : null}
        {loggedUser?.role === 'distributor' ?
          <div>
            <FormSelect disabled onChange={(value) => onDistributorChange(value)} label='Distributor' name='distributor' placeholder={'Select Distributor'} options={distributorsOptions()} required initialValue={loggedUser?._id} />
            <FormSelect onChange={(value) => onSubDistributorChange(value)} label='SubDistributor' name='subDistributor' placeholder={'Select Sub Distributor'} options={subDistributorsOptions()} required initialValue={currentDealer ? currentDealer.subDistributor._id : null} />
          </div>
          : null}
        {/* <FormInputNumber label='Amount' name='amount' required initialValue={currentDealer ? currentDealer.wallet.amount : 0} /> */}
        <FormInputNumber label={`Discount Rate`} name='discountRate' required initialValue={currentDealer ? currentDealer.discountRate : 0} max={100} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormInputNumber label={`Amount ${currentDealer ? ': ' + currentDealer.wallet?.amount : ''}`} name='amount' required initialValue={0} max={selectedSubDistributor?.wallet?.amount} />
          {currentDealer ? <Button style={{ marginLeft: 12 }} onClick={
            () => {
              const data = form.getFieldsValue()
              if (currentDealer?.wallet.amount + data.amount < 0) {
                console.log(currentDealer?.wallet.amount, data.amount)
                openNotificationWithIcon('error', 'Error!', `Dealer doesn't have sufficient balance`)
              } else {
                onAmountUpdate(currentDealer?._id, { amount: data.amount })
              }
              onClose()
            }} type={'primary'}>Update Amount</Button> : null}
        </div>
        {/* {selectedSubDistributor ? <p>Available Amount:{selectedSubDistributor.wallet.amount}</p> : null} */}
        <FormSwitch name={'allowBoost'} label={'Allow Boost'} required initialValue={currentDealer?.allowBoost} />
        <FormSwitch name={'allowSimple'} label={'Allow Simple'} required initialValue={currentDealer?.allowSimple} />
        {selectedSubDistributor ? <p>Available Amount:{selectedSubDistributor?.wallet.amount}</p> : currentDealer ? currentDealer?.distributor?.wallet.amount : null}
        <FormHiddenSubmitButton submitRef={submitRef} />
      </Form>
    </Modal >
  )
}

DealerModal.propTypes = {
  modalVisible: proptypes.bool.isRequired,
  setModalVisible: proptypes.func.isRequired,
  onAdd: proptypes.func.isRequired,
  currentDealer: proptypes.object,
  setCurrentSubDistributor: proptypes.func.isRequired,
  onUpdate: proptypes.func.isRequired,
  onDelete: proptypes.func.isRequired
};

export default DealerModal
