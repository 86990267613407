import { Form, } from "antd"
import Modal from "antd/lib/modal/Modal"
import { useRef } from "react";
import { FormHiddenSubmitButton, FormInput, FormInputNumber, FormSwitch, } from "../../../../components/FormItems/FormItems"
import './DistributorModal.scss'
import proptypes from 'prop-types'

const DistributorModal = ({ modalVisible, setModalVisible, currentDistributor, onAdd, onUpdate, onDelete, setCurrentDistributor }) => {
  const [form] = Form.useForm();
  const submitRef = useRef()

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const onClose = () => {
    setModalVisible(false)
    setCurrentDistributor(null)
    form.resetFields()
  }
  return (
    <Modal
      className='todo_modal'
      style={{ borderRadius: 8 }}
      bodyStyle={{ borderRadius: 8 }}
      destroyOnClose
      title={currentDistributor ? 'Update Distributor' : 'Add Distributor'}
      onOk={() => submitRef.current.click()}
      visible={modalVisible}
      onCancel={onClose}>
      <Form className='form' layout='vertical' form={form} preserve={false} onFinishFailed={onFinishFailed} onFinish={(values) => {
        console.log('ok')
        if (currentDistributor) {
          onUpdate(values)
          onClose()
        } else {
          onAdd(values)
          onClose()
        }
        setModalVisible(false)
      }}>
        <FormInput label='Name' name='name' required type='text' placeholder='Enter Name' initialValue={currentDistributor ? currentDistributor.name : ''} styles={null} />
        <FormInput disabled={currentDistributor} label='Username' name='username' required type='text' placeholder='Enter Username' initialValue={currentDistributor ? currentDistributor.username : ''} styles={null} />
        {currentDistributor ? null : <FormInput label='Password' name='password' required type='password' placeholder='Enter Password' styles={null} />}
        <FormInputNumber maxlength={10} styles={{ width: '100%' }} label='Phone' name='phone' required type='text' placeholder='Enter Phone' initialValue={currentDistributor ? currentDistributor.phone : null} />
        <FormInputNumber label='Amount' name='amount' required initialValue={currentDistributor ? currentDistributor.wallet.amount : 0} />
        <FormSwitch name={'limitSubDistributor'} label={'Limit Sub Distributors'}  initialValue={currentDistributor?.limitSubDistributor} />
        <FormSwitch name={'limitDealer'} label={'Limit Dealers'}  initialValue={currentDistributor?.limitDealers} />
        <FormHiddenSubmitButton submitRef={submitRef} />
      </Form>
    </Modal >
  )
}

DistributorModal.propTypes = {
  modalVisible: proptypes.bool.isRequired,
  setModalVisible: proptypes.func.isRequired,
  onAdd: proptypes.func.isRequired,
  currentDistributor: proptypes.object,
  setCurrentDistributor: proptypes.func.isRequired,
  onUpdate: proptypes.func.isRequired,
  onDelete: proptypes.func.isRequired
};

export default DistributorModal
