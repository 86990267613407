import { DeleteOutlined, EditOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Select, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DealerModal from './DealerModal/DealerModal';
import { setAllDealers, setCurrentDealer, setUser } from '../../../redux/actions/user.actions';
import { openNotificationWithIcon } from '../../../utils/Notification';
import { addNewDealer, deleteDealer, getAllDealer, updateDealer, updateDealerAmount } from '../../../services/dealer.services';
import { getAllDistributors } from '../../../services/distributor.services';
import { getAllSubDistributor } from '../../../services/subDistributor.services';
import { setLoading } from '../../../redux/actions/app.actions';
import { verifyUser } from '../../../services/auth.services';
import { searchUser } from '../../../services/user.services';

const Option = Select.Option
let timeOut = null

export default function Dealers() {

  const dispatch = useDispatch();



  const [modalVisible, setModalVisible] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const [searchText, setSearchText] = useState('')
  const [user, setUser] = useState(null)

  const allDealers = useSelector(state => state.user.allDealers)
  const currentDealer = useSelector(state => state.user.currentDealer)
  const loggedUser = useSelector(state => state.user.currentUser)

  const getData = async () => {
    dispatch(setLoading(true))
    await dispatch(getAllDealer())
    await dispatch(getAllDistributors())
    await dispatch(getAllSubDistributor())
    await dispatch(verifyUser())
    dispatch(setLoading(false))
  }

  useEffect(() => {
    getData()
  }, []); //eslint-disable-line

  const onAddHandler = async (values) => {
    dispatch(setLoading(true))
    let data = { ...values }
    if (!data.subDistributor) {
      data.subDistributor = loggedUser._id
    }
    await dispatch(addNewDealer({ ...data, role: 'dealer' }, allDealers))
    getData()
    dispatch(setCurrentDealer(null))
  }

  const onUpdateHandler = async (values) => {
    dispatch(setLoading(true))
    delete values.amount
    await dispatch(updateDealer(currentDealer?._id, { ...values, }, allDealers))
    setUser(null)
    setOptions([])
    getData()
    dispatch(setCurrentDealer(null))
  }

  const onDeleteHandler = async (id) => {
    dispatch(setLoading(true))
    await dispatch(deleteDealer(id, allDealers))
    setUser(null)
    setOptions([])
    getData()
    dispatch(setCurrentDealer(null))
  }


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
    },
    {
      title: 'Sub Distributor',
      dataIndex: 'subDistributor',
      key: 'subDistributor',
      render: (col) => col?.name
    },
    {
      title: 'Amount',
      dataIndex: 'wallet',
      key: 'amount',
      render: txt => txt ? `$${txt?.amount}` : '$0'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      width: 180,
      render: (text, record) => (
        <span>
          <Button type='primary' icon={<EditOutlined />} onClick={() => {
            dispatch(setCurrentDealer(record))
            setModalVisible(true)
          }} />
          <Divider type="vertical" />

          <Popconfirm disabled={false} placement='topRight' title="Are you sure to delete this dealer?" onConfirm={() => {
            onDeleteHandler(record._id)
          }}
          >
            <Button disabled={false} type='danger' icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    }
  ];
  const onAmountUpdate = async (id, data) => {
    dispatch(setLoading(true))
    await dispatch(updateDealerAmount(id, data, allDealers))
    setOptions([])
    getData()
    setUser(null)
    // openNotificationWithIcon('success', 'Success!', 'SubDistributor Deleted Succesfuly')
    dispatch(setCurrentDealer(null))
  }


  const onSearchUser = (value) => {
    setSearchText(value)
    clearTimeout(timeOut)
    if (value) {
      timeOut = setTimeout(async () => {
        setFetching(true)
        const options = await searchUser(value, 'dealer')
        setOptions(options)
        setFetching(false)
      }, 1000)
    }
  }
  return (
    <div>

      <DealerModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        currentDealer={currentDealer}
        setCurrentDealer={(value) => dispatch(setCurrentDealer(value))}
        onAdd={onAddHandler}
        onDelete={onDeleteHandler}
        onUpdate={onUpdateHandler}
        onAmountUpdate={onAmountUpdate}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 16 }}>
        {loggedUser?.role !== 'dealer' &&
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginRight: 20 }}>User: </p>
            <Select
              className='form_item'
              autoClearSearchValue={false}
              allowClear
              showSearch
              value={user}
              style={{ width: 400, marginRight: 20 }}
              filterOption={false}
              searchValue={searchText}
              onSearch={onSearchUser}
              onChange={(val) => {
                setUser(val)
                dispatch(setAllDealers(options.filter(d => d._id === val)))
              }}
              notFoundContent={fetching ? <Spin size="small" /> : null}
            >
              {options.map(val => <Option key={val._id} label={val.name} value={val._id}>{val.name} ({val.role})</Option>)}
            </Select>
            <Button icon={<UndoOutlined />} onClick={() => {
              setOptions([])
              getData()
              setUser(null)

            }} />
          </div>}
        {!loggedUser?.limitDealer && <Button type='primary' onClick={() => setModalVisible(true)}>
          Add Dealer
        </Button>}
      </div>

      <div style={{ overflow: 'auto', height: '70vh' }}>
        <Table dataSource={allDealers} columns={columns} />
      </div>
    </div>
  )
}
